import MeditateImg from '../img/illustrations/meditate.svg'
import WarningImg from '../img/illustrations/danger-exclamation.svg'
import 'bootstrap/js/dist/modal'
import wrap_img_fluid from '../js/wrap_img_fluid.js'

document.addEventListener("DOMContentLoaded",function(){
	$("[data-behavior='delete']").click(function(e){
		var card_container = $(this).data("scholarship-card");
		var card_status = $(this).data("card-status") || 'active';
		var card_counter = "#" + card_status + "_counter"
		e.preventDefault();
		e.stopPropagation();
		Swal.fire({
		  title: 'Are you sure you want to delete this scholarship?',
		  text: "All applications to this scholarship will be lost!",
		  imageUrl: WarningImg,
		  imageWidth: 100,
		  imageHeight: 100,
		  buttonsStyling: false,
		  customClass: {
		  	confirmButton: 'btn btn-danger ml-1',
		  	cancelButton: 'btn btn-secondary mr-1'
		  },
		  showClass: {
		    popup: 'animated fadeIn faster'
		  },
		  hideClass: {
		    popup: 'animated fadeOut faster'
		  },
		  reverseButtons: true,
		  showCancelButton: true,
		  focusCancel: true,
		  confirmButtonText: 'Yes, delete scholarship'
		}).then((result) => {
		  if (result.value) {
		  	$.ajax({
		        type: "POST",
		        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		        url: $(this).attr("href"),
		        dataType: "json",
		        data: {"_method":"delete"},
		        success: function(){
		        	toastr.success('Scholarship successfully deleted.');
		        	location.reload(true);
		        	// $(card_container).remove();

		        	// if(parseInt($(card_counter).text()) - 1 == 0){
		        	// 	$(card_counter).remove();
		        	// 	$("#ScholarshipCreationCallActionContainer").removeClass("invisible-element");
		        	// }else{
		        	// 	$(card_counter).text(parseInt($(card_counter).text()) - 1);
		        	// }
	          }
		    });
		  }
		})
	});

	$("[data-behavior='cancel-scholarship-application']").click(function(e){
		e.preventDefault();
		e.stopPropagation();
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You won't be able to revert this!",
		  imageUrl: WarningImg,
		  imageWidth: 100,
		  imageHeight: 100,
		  buttonsStyling: false,
		  customClass: {
		  	confirmButton: 'btn btn-danger ml-1',
		  	cancelButton: 'btn btn-secondary mr-1'
		  },
		  showClass: {
		    popup: 'animated fadeIn faster'
		  },
		  hideClass: {
		    popup: 'animated fadeOut faster'
		  },
		  reverseButtons: true,
		  showCancelButton: true,
		  focusCancel: true,
		  confirmButtonText: 'Yes, cancel my application.'
		}).then((result) => {
		  if (result.value) {
		  	$.ajax({
		        type: "POST",
		        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		        url: $(this).attr("href"),
		        dataType: "json",
		        data: {"_method":"delete"},
		         success: function(){
		        	location.reload(true);
		        }
		    });
		  }
		})
	});

	$('#ViewScholarshipCardModal').on('show.bs.modal', function (event) {

	  var button = $(event.relatedTarget); 
	  var scholarship_id = button.data('scholarship_id');
	  var modal = $(this);

	  var scholarship_card = $(button).parentsUntil("#scholarship-"+ scholarship_id +"-card").parent().clone();
	  modal.find('.card-modal-content-primary').empty();
	  modal.find('#offer-letter-container').empty()
	  modal.find('#CancelScholarshipApplicationID').addClass('d-inline-none');
	  modal.find('.card-modal-content-primary').append(scholarship_card[0]);
	  modal.find('#card_action_button_row').remove();
	  var scholarship_application_link = modal.find('#ApplyScholarshipBtn').data("apply-url");
	  modal.find('#ApplyScholarshipBtn').attr("href", scholarship_application_link + "/" + scholarship_id );

	  if($("#CancelScholarshipApplicationID").length){
	  	var scholarship_application_cancel_link = modal.find('#CancelScholarshipApplicationID').data("cancel-url");
	  	modal.find('#CancelScholarshipApplicationID').attr("href", scholarship_application_cancel_link + "/" + scholarship_id );
	  }
	  
	  $.ajax({
	    type: "GET",
	    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
	    url: "/scholarships/" + scholarship_id,
	    dataType: "json",
	    success: function(data){
	    	if(data['data']['attributes']['institution_profile'] != null){
	    		var website = "<a class='mb-0 small d-block btn-link' href="+ data['data']['attributes']['institution_profile'] +">View Institution Profile</a>"
	    		modal.find('.card-view-profile').append(website);
	    	}
	    	if(data['data']['attributes']['offer_letter'] != null){
	    		modal.find('#offer-letter-container').html("<a href="+ data['data']['attributes']['offer_letter']+ "><button class='btn btn-primary d-inline-block'>Download Offer Letter</button></a>");
	    		modal.find('#CancelScholarshipApplicationID').addClass('d-none').removeClass('d-inline-block');
	    	}else{
	    		modal.find('#CancelScholarshipApplicationID').removeClass('d-none');
	    	}
	    	if(data['data']['attributes']['invited_scholarship_path'] != null){
	    		modal.find('#ApplyScholarshipBtn').removeClass("invisible-element");
	    		modal.find('#ApplyScholarshipBtn').attr("href", data['data']['attributes']['invited_scholarship_path'] );
	    	}
	    	modal.find('#scholarshipCardModalInfo').empty();
	    	if(data['data']['attributes']['additional_information'] != '') {
	    		modal.find('#scholarshipCardModalInfo').append("<h2 class='accordion-title mb-0'><button class='btn institution-link btn-block text-left pl-0 py-3' type='button' data-toggle='collapse' data-target='#collapseA' aria-expanded='false' aria-controls='collapseA'>Scholarship Description <i class='fas fa-angle-right'></i></button></h2><div id='collapseA' class='collapse show text-muted mt-3' aria-labelledby='headingOne' data-parent='#scholarshipCardModalInfo'>"+ data['data']['attributes']['description'] +"</div><h2 class='accordion-title mb-0'><button class='btn institution-link btn-block text-left pl-0 py-3' type='button' data-toggle='collapse' data-target='#collapseB' aria-expanded='false' aria-controls='collapseB'>Eligibility Criteria <i class='fas fa-angle-right'></i></button></h2><div id='collapseB' class='collapse show text-muted mt-3' aria-labelledby='headingTwo' data-parent='#scholarshipCardModalInfo'>"+ data['data']['attributes']['eligibility_criteria'] +"</div><h2 class='accordion-title mb-0'><button class='btn institution-link btn-block text-left pl-0 py-3 collapsed' type='button' data-toggle='collapse' data-target='#collapseC' aria-expanded='true' aria-controls='collapseC'>Additional Information <i class='fas fa-angle-right'></i></button></h2><div id='collapseC' class='collapse text-muted mt-3' aria-labelledby='headingThree' data-parent='#scholarshipCardModalInfo'>"+ data['data']['attributes']['additional_information'] +"</div>");
	       	}else{
	    	modal.find('#scholarshipCardModalInfo').append("<h2 class='accordion-title mb-0'><button class='btn institution-link btn-block text-left pl-0 py-3' type='button' data-toggle='collapse' data-target='#collapseA' aria-expanded='false' aria-controls='collapseA'>Scholarship Description <i class='fas fa-angle-right'></i></button></h2><div id='collapseA' class='collapse show text-muted mt-3' aria-labelledby='headingOne' data-parent='#scholarshipCardModalInfo'>"+ data['data']['attributes']['description'] +"</div><h2 class='accordion-title mb-0'><button class='btn institution-link btn-block text-left pl-0 py-3' type='button' data-toggle='collapse' data-target='#collapseB' aria-expanded='false' aria-controls='collapseB'>Eligibility Criteria <i class='fas fa-angle-right'></i></button></h2><div id='collapseB' class='collapse show text-muted mt-3' aria-labelledby='headingTwo' data-parent='#scholarshipCardModalInfo'>"+ data['data']['attributes']['eligibility_criteria'] +"</div>");
	       	}
	       	wrap_img_fluid("#ViewScholarshipCardModal");
	       	if(data['data']['attributes']['courses_offer'] != '') {
	    		modal.find('#scholarshipCardModalInfo').append("<h2 class='accordion-title mb-0'><button class='btn institution-link btn-block text-left pl-0 py-3 collapsed' type='button' data-toggle='collapse' data-target='#collapseD' aria-expanded='false' aria-controls='collapseD'>Courses Offered <i class='fas fa-angle-right'></i></button></h2><div id='collapseD' class='collapse text-muted mt-3' aria-labelledby='headingFour' data-parent='#scholarshipCardModalInfo'>"+ data['data']['attributes']['courses_offer'] +"</div>");
	       	}
	       	if(data['data']['attributes']['countries_offer'] != '') {
	    		modal.find('#scholarshipCardModalInfo').append("<h2 class='accordion-title mb-0'><button class='btn institution-link btn-block text-left pl-0 py-3 collapsed' type='button' data-toggle='collapse' data-target='#collapseE' aria-expanded='false' aria-controls='collapseE'>Countries Offered <i class='fas fa-angle-right'></i></button></h2><div id='collapseE' class='collapse text-muted mt-3' aria-labelledby='headingFive' data-parent='#scholarshipCardModalInfo'>"+ data['data']['attributes']['countries_offer'] +"</div>");
	       	}
	    }
	  });
	})

	$('#ViewScholarshipCardModal').on('hide.bs.modal', function () {
		var modal = $(this);
		modal.find('.card-modal-content-primary').empty();
		modal.find('#offer-letter-container').empty()
	})
});